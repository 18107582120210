export default class ToolsComponent {
    constructor() {
        ToolsComponent.langSwitcher()
    }

    static langSwitcher() {
        const current_lang = $('.tools__item__languages__current')
        const list_langs = $('.tools__item__languages__langs')
        const list_lang_item = $('.tools__item__languages__langs a')

        //Open submenu
        current_lang.on('click', () => {
            list_langs.toggleClass('tools__item__languages__langs--open')
        })

        //Select lang
        list_langs.on('click', () => {
            list_langs.toggleClass('tools__item__languages__langs--open')
        })

        //Change Header Language on click
        list_lang_item.on('click', function () {
            list_lang_item.show()
            $(this).hide()
            current_lang.text($(this).text())
        })

        // Fix current lang
        let currentLang = document.getElementsByTagName('html')[0].getAttribute('lang')
        if (currentLang.length > 2) {
            currentLang = currentLang.split('-')[0]
        }

        //Init select lang
        $(window).on('load', () => {
            setTimeout(() => {
                const current_language = $('html').attr('lang').replace(/-.*!/gi, '')
                current_lang.text(current_language)

                //Remove current language on list (init)
                list_lang_item.each(function () {
                    if ($(this).text() === currentLang.toUpperCase()) {
                        $(this).hide()
                    }
                })

                $('.tools__item__languages__current').html(currentLang.toUpperCase())
            }, 300)
        })
    }
}
