export default class NavbarComponent {
	constructor() {
		NavbarComponent.sticky()
		NavbarComponent.burger()
		NavbarComponent.sidebar()
		NavbarComponent.bookingDateMemory('hosting');
		NavbarComponent.bookingDateMemory('activities');
		NavbarComponent.bookingDateMemory('booking');
	}

	static sticky() {
		let lastScrollTop = 0

		$(window).on('load scroll', function () {
			const offset = 150
			const offsetOnScroll = 100
			const navbar = $('[data-id="wpiris-navbar"]')
			const scrollTop = $(this).scrollTop()
			let full = false

			//Hidden comportment
			if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight() + 50) {
				$('body').addClass('header-is-hidden')
			} else {
				$('body').removeClass('header-is-hidden')
			}

			// Detect if header is on banner/video mode
			if ($('body.has-banner').length > 0) {
				full = true
			}

			lastScrollTop = scrollTop

			if (scrollTop > offset) {
				navbar.removeClass('navbar--ontop')
				navbar.addClass('navbar--onscroll')
				if (full === true) {
					NavbarComponent.changeLogoColor('classic')
				}
			} else if (scrollTop < offsetOnScroll) {
				navbar.removeClass('navbar--onscroll')
				navbar.addClass('navbar--ontop')
				if (full === true) {
					NavbarComponent.changeLogoColor('banner')
				}
			}
		})
	}

	static changeLogoColor(color) {
		const logo = $('[data-id="wpiris-logo"]')
		let logoBanner
		let logoClassic

		logoBanner = IRISCollectionCustomer.imageLogoBanner
		logoClassic = IRISCollectionCustomer.imageLogo

		if (color === 'banner') {
			logo.attr('src', logoBanner)
		} else if (color === 'classic') {
			logo.attr('src', logoClassic)
		}
	}

	static burger() {
		const body = $('body')
		const trigger = '[data-trigger="wpiris-menu-toggle"]'
		const more = '[data-trigger="wpiris-menu-more"]'
		const prev = '[data-trigger="wpiris-menu-prev"]'
		const submenu = '[data-trigger="wpiris-menu-submenu"]'

		//Open/Close menu
		$(trigger).on('click', () => {
			body.toggleClass('menu-open')

			//Change logo on mobile
			if (window.matchMedia('(max-width: 650px)').matches) {
				if (body.hasClass('menu-open')) {
					// NavbarComponent.changeLogoColor('classic');
				} else {
					if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
						NavbarComponent.changeLogoColor('banner')
					}
				}
			}
		})

		//Open/Close submenu
		$(more).on('click', function () {
			$(this).next(submenu).toggleClass('submenu-open')
		})

		$(prev).on('click', (e) => {
			e.preventDefault()
			$(submenu).removeClass('submenu-open')
		})
	}

	static sidebar() {
		$('[data-sidebar-trigger]').on('click', function () {
			$('[data-sidebar], [data-sidebar-trigger] ,[data-sidebar-id]').removeClass('is-active')
			const sidebarId = $(this).attr('data-sidebar-trigger')
			if (sidebarId) {
				$(
					`[data-sidebar], [data-sidebar-trigger=${sidebarId}], [data-sidebar-id=${sidebarId}]`,
				).addClass('is-active')
			}
		})

		$('input[name="startdate"]').on('change', function () {
			$(this).siblings().attr('min', $(this).val())
		})
		$('input[name="enddate"]').on('change', function () {
			$(this).siblings().attr('max', $(this).val())
		})

		// Form submit
		$('[data-engine-form]').submit(function (e) {
			e.preventDefault()
			const id = $(this).attr('data-engine-form')
			const i18n = JSON.parse(IRISCollectionCustomer.i18n)

			const values = {}
			$.each($(this).serializeArray(), (i, field) => {
				values[field.name] = field.value
			})

			switch (id) {
				case 'hosting':
					if (values.url && values.startdate && values.enddate) {
						const url = `${values.url + i18n.search}/marketplace/${values.startdate}/${values.enddate}/${values.nbperson ? `${values.nbperson}/0/` : '0/0'}`
						window.location.href = url
					}
					break
				case 'activities':
					if (values.url && values.startdate && values.enddate) {
						const url = `${values.url + i18n.search}/marketplace/${values.startdate}/${values.enddate}/0/0/`
						window.location.href = url
					}
					break
				case 'booking':
					if (values.url && values.startdate && values.enddate) {
						const url = `${values.url + i18n.search}/agenda/${values.startdate}/${values.enddate}/`
						window.location.href = url
					}
					break
			}
		})
	}

	static bookingDateMemory(formType) {
		$(`[data-engine-form="${formType}"] input[name="startdate"]`).on('change', function () {
			const startDate = $(this).val()
			const $endDateInput = $(this).parent().siblings().find('input[name="enddate"]')

			// Set the minimum date of the end date input to the selected start date
			$endDateInput.attr('min', startDate)

			// If the current end date is before the new start date, adjust it
			if (new Date($endDateInput.val()) < new Date(startDate)) {
				$endDateInput.val(startDate)
			}
		})
	}
}
